import React from "react";
import "./coursePageContent.css";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/utils";

const CoursePageSpanishContent = () => {
  return (
    <div className="bl_coursePage-wrapper section__padding-page">
      <h1>Individuálny, skupinový či firemný kurz? Žiaden problém!</h1>
      <div className="bl_coursePage-container">
        <div className="bl_coursePage-detail">
          <h2>Pre viac informácií, si prosím vyberte kurz</h2>
          <ul className="accordion">
            <li>
              <input type="radio" name="accordion" id="first" defaultChecked />
              <label htmlFor="first" id="fourthLabel">
                Individuálny kurz
              </label>
              <div className="content">
                <p>
                  <b>
                    Pre tých, ktorí uprednostňujú individuálny prístup k štúdiu:
                  </b>
                </p>
                <p>
                  Španielčina na cestovanie alebo na osobné obohatenie? Náš
                  individuálny kurz španielčiny sa zameria na rozvoj vašich
                  komunikačných zručností, slovnej zásoby, rovnako ako aj
                  porozumenia hovorenej a písanej španielčiny. Počas celého
                  kurzu spolupracujete úzko s kvalifikovaným lektorom
                  španielčiny, ktorý zhodnotí vašu aktuálnu jazykovú úroveň a
                  vypracuje individuálny plán.
                </p>
              </div>
            </li>
            <li>
              <input type="radio" name="accordion" id="second" />
              <label htmlFor="second" id="fifthLabel">
                Skupinový kurz <span>(max. 5 študentov)</span>
              </label>
              <div className="content">
                <p>
                  <b>
                    Pripravte sa na individuálnu pozornosť v skupine ako nikdy
                    predtým!
                  </b>
                </p>
                <p>
                  Vďaka malej skupine sa naši skúsení lektori môžu zamerať na
                  individuálne potreby každého študenta a prispôsobiť výučbu
                  jeho konkrétnym jazykovým cieľom. Dostanete spätnú väzbu na
                  mieru, prispôsobené učebné materiály a cielené poradenstvo,
                  aby ste dosiahli svoj plný potenciál. Zdieľajte skúsenosti,
                  oslavujte úspechy a navzájom sa motivujte na každom kroku.
                </p>
              </div>
            </li>
            <li>
              <input type="radio" name="accordion" id="eighth" />
              <label htmlFor="eighth" id="eighthLabel">
                Detský kurz
              </label>
              <div className="content">
                <p>
                  <b>
                    Poďme spolu vybudovať pevné základy pre budúcnosť vášho
                    dieťaťa!
                  </b>
                </p>
                <p>
                  Naše interaktívne kurzy sú špeciálne navrhnuté tak, aby sa
                  deti všetkých vekových kategórií učili jazyky zábavne a
                  pútavo. Od pútavých príbehov a hier až po interaktívne
                  cvičenia zabezpečujeme pohlcujúci zážitok, ktorý podnecuje
                  zvedavosť a rozvíja ich jazykové schopnosti. Prihláste sa vaše
                  dieťa na náš kurz ešte dnes a sledujte, ako vaše ratolesti
                  rozkvitnú v sebavedomých španielčinárov.
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="bl_coursePage-general">
          <h2>„¡Bienvenidos!“</h2>
          <p>
            <b>Vitajte, na našom kurze španielčiny!</b> Či už sa pripravujete na
            cestu do španielsky hovoriacej krajiny, chcete si zlepšiť kariérne
            vyhliadky, alebo jednoducho chcete spoznať nový jazyk, náš kurz
            španielčiny je vašou zárukou úspechu. Urobte prvý krok k plynulému
            ovládaniu jazyka a kultúrnemu obohateniu a prihláste sa ešte dnes!
          </p>
          <h2>Prečo si vybrať práve nás?</h2>
          <ul>
            <li>
              <b>Zábavné a interaktívne učenie:</b> Rozlúčte sa s nudnými
              učebnicami! Naše živé lekcie vás zaujmú a nadchnú pre učenie.
            </li>
            <li>
              <b>Flexibilita na dosah ruky:</b> Čas, deň a spôsob výučby si
              vyberáte vy! Či máte záujem o osobné hodiny v centre Bratislavy
              skoro ráno alebo preferujete online výuku večer, sme tu na to, aby
              sme sa vám prispôsobili.
            </li>
            <li>
              <b>Individuálny prístup:</b> Vaše ciele sú našou najvyššou
              prioritou. Nevyučujeme žiadnu konkrétnu metodiku a preto vám náš
              tím prispôsobí lekcie tak, aby vyhovovali vašim
              jedinečným potrebám.
            </li>
          </ul>
          <h3>Ste pripravení vydať sa na túto veľkolepú jazykovú cestu?</h3>
          <p className="bl_coursePage-cta">
            <b>Nečakajte</b> - vaše španielske dobrodružstvo sa začína teraz!
          </p>
          <Link
            className="bl_coursePage-link"
            to={"/registracia"}
            onClick={scrollToTop}
          >
            Prihláste sa na našu bezplatnú ukážkovú hodinu.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CoursePageSpanishContent;
