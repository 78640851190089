import React from "react";
import BasicTable from "./timetable/TimeTable";
import CampForm from "./form/CampForm";
import { useForm } from "react-hook-form";
import { Img } from "react-image";

import "react-toastify/dist/ReactToastify.css";
import "./campPage.css";
import { campImages } from "./campImages.ts";

function Camp() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    control,
  } = useForm();

  return (
    <div className="bl_camp-wrapper section__padding-page">
      <h1>LETNÝ ANGLICKÝ TÁBOR 2025 🌞</h1>
      <h2>Opäť spolu a ešte viac zábavy!</h2>
      <div className="bl_camp-content_head">
        <div>
          <div>
            <p>
              <b
                className="text-orange"
                style={{ fontSize: "1.2rem", lineHeight: "1rem" }}
              >
                Minulý rok to bolo skvelé – tento rok to bude ešte lepšie!
              </b>
              <br></br>
              Predstav si zábavné jazykové hry, vzrušujúce aktivity vonku a
              kopec smiechu každý deň. Čaká ťa:
            </p>
            <ul>
              <li>Prechádzka k jazeru Veľký Dráždiak</li>
              <li>Anglický filmový deň</li>
              <li>Napínavé hry a tímové súťaže</li>
              <li>Ešte viac angličtiny zábavnou formou!</li>
            </ul>
          </div>
          <p>
            Náš tábor je vhodný pre deti <b>od 5 do 12 rokov</b>. Počas hodín
            angličtiny budú deti rozdelené do viacerých skupín podľa ich vekovej
            kategórie.
          </p>
          <div className="flex-col">
            <p style={{ fontSize: "1.2rem" }}>
              <b className="text-orange">KDE?</b> V Petržalskom centre pre
              rodiny
            </p>
            <p style={{ fontSize: "1.2rem" }}>
              <b className="text-orange">KEDY?</b>
            </p>
            <ul style={{ fontSize: "1.2rem" }}>
              <li>14.07.2025 - 18.07.2025</li>
              <li>21.07.2025 - 25.07.2025</li>
              <li>11.08.2025 - 15.08.2025</li>
            </ul>
          </div>
          <div className="flex-col">
            <p>
              Cena denného tábora 2025: <b>€199</b> (v prípade 2 detí{" "}
              <b>€189/dieťa</b>). Cena tábora zahŕňa: hodiny angličtiny vrátane
              študijných materiálov, celodennú starostlivosť, desiatu, obed,
              olovrant a pitný režim.{" "}
            </p>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <p>
              <b
                className="text-orange"
                style={{ fontSize: "1.2rem", lineHeight: "1rem" }}
              >
                Prečo si vybrať tábor Bratilingua?
              </b>
            </p>
            <ul>
              <li className="orange-dot">
                <b>Skúsení lektori:</b> Naše lektorky majú bohaté skúsenosti s
                prácou s deťmi všetkých vekových kategórií a z rôznych
                prostredí. Sú vyškolené v oblasti vývoja detí, riešenia
                konfliktov a núdzových postupov, aby s istotou a profesionalitou
                zvládli každú situáciu.
              </li>
              <li className="orange-dot">
                <b>Bezpečnosť na prvom mieste:</b> Bezpečnosť vášho dieťaťa je
                našou prioritou. Počas celého táboru nás bude sprevádzať
                zdravotník, ktorý bude dohliadať na bezpečnosť a zdravotnú
                spôsobilosť všetkých detí.
              </li>
              <li className="orange-dot">
                <b>Osobný prístup:</b> Vďaka nízkemu počtu táborníkov
                uprednostňujeme individuálnu pozornosť pre každé dieťa.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <BasicTable />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          gap: "1rem",
          margin: "2rem 0",
        }}
      >
        {campImages?.map((image) => (
          <Img
            style={{
              height: "300px",
              objectFit: "contain",
              borderRadius: "1rem",
            }}
            src={image.src}
            alt={image.alt}
            key={image.src}
          />
        ))}
      </div>
      <h2 style={{ marginBottom: "0", marginTop: "3rem" }}>
        Záväzná prihláška
      </h2>
      <CampForm
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        setValue={setValue}
        reset={reset}
        watch={watch}
        control={control}
      />
    </div>
  );
}

export default Camp;
